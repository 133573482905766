<template>
  <div class="facial-features q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['rednessLimitedToPimples'])">
      <single-answer-question
        title="label.rednessLimitedToPimples"
        :value="rednessLimitedToPimples"
        :options="$options.yesNoOptions"
        dysfunction="skin-redness"
        yes-no
        @input="onFieldChange('rednessLimitedToPimples', $event)"
      />
    </question-with-error-wrap>

    <question-with-error-wrap
      class="q-mt24 q-mt32-md"
      :error="getFieldsError(['rednessLocations'])"
    >
      <face-zone-multi-select
        id="facial-redness-locations"
        title="label.rednessLocations"
        :value="rednessLocations"
        :options="$options.rednessLocationsOptions"
        :gender="gender"
        hint="label.selectAllRegionsThatApply"
        field-name="redness"
        @input="onFieldChange('rednessLocations', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';
import FaceZoneMultiSelect from '@/modules/questionnaire/new-design-components/questions/face-zone-input/FaceZoneMultiSelect';

import { YES_NO_RESPONSE_OPTIONS } from '@/modules/questionnaire/constants/questions';
import { FACE_ZONE } from '@/modules/questionnaire/api/constants';

const REDNESS_LOCATIONS_OPTIONS = [
  { value: FACE_ZONE.FOREHEAD, text: 'faceZone.forehead' },
  { value: FACE_ZONE.NOSE, text: 'faceZone.nose' },
  { value: FACE_ZONE.CHEEKS_AND_CHEEKBONES, text: 'faceZone.cheeksAndCheekbones' },
  { value: FACE_ZONE.CHIN, text: 'faceZone.chin' }
];

export default {
  name: 'RednessLocationsTemplate',
  components: { QuestionWithErrorWrap, SingleAnswerQuestion, FaceZoneMultiSelect },
  yesNoOptions: YES_NO_RESPONSE_OPTIONS,
  rednessLocationsOptions: REDNESS_LOCATIONS_OPTIONS,
  mixins: [stepTemplateMixin],
  props: {
    gender: {
      type: String,
      required: true
    },
    rednessLimitedToPimples: {
      type: String,
      default: ''
    },
    rednessLocations: {
      type: Array,
      default: () => []
    }
  }
};
</script>
